/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  // overflow-y: scroll;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  color: #222;
}
.wrap {
  padding: 30px 0;
}
.container-fluid.but-fixed {
  max-width: 1356px;
}
@media (min-width: 1400px) {
  .container-fluid.but-fixed {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}
.footer {
  padding-top: 20px;
  padding-bottom: 15px;
  width: 100%;
  background-color: #f5f5f5;
  color: #555;
  border-top: 1px solid #e4e4e4;
  font-size: 0.9rem;
  p {
    margin: 0 0 5px 0;
  }
  p.trustmark {
    display: none;
    margin: 0;
  }
  a {
    color: #555;
  }
  .social a {
    border: 0;
  }
  img {
    display: inline-block;
  }
  #trustwaveSealImage {
    margin: 0 10px 30px 0;
  }
}

/* --------- asc styles --------- */
#header {
  background: rgba(255, 255, 255, 0.8);
  a.logo {
    border: 0;
    width: 80%;
    max-width: 750px;
    img {
      margin-right: 5px;
      width: auto;
      height: auto;
      max-width: 40px;
    }
    span.big,
    span.small {
      font-size: 1.5em;
      color: #555;
      line-height: 1em;
    }
    span.big {
      font-weight: 600;
      font-family: var(--font-family);
    }
    span.small {
      font-size: 0.9em;
      font-family: var(--font-family);
      line-height: 1.5em;
    }
  }
  #support {
    display: none;
    float: right;
    margin: 0 0 0 0;
    width: auto;
    p {
      float: right;
      margin: 2px 12px 0 0;
      strong {
        font-size: 16px;
      }
    }
    a.login {
      float: right;
      margin: 0 0 0 0;
      padding: 0 12px 2px 12px;
      font-weight: normal;
      color: #fff;
      background: $theme-primary;
      text-decoration: none;
      border-radius: 0 0 4px 4px;
      border: 0;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e828';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        background: lighten($theme-primary, 7.5%);
      }
    }
    a.help-center {
      margin: 0 10px 0 0;
      font-weight: normal;
      color: #555;
      border: 0;
      text-decoration: none;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e830';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        &:before {
          color: $theme-primary;
        }
      }
    }
  }
}

#banner-wrap {
  background: #9199a4 url(../images/banner-bg.jpg) repeat-x;
  #banner {
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.75);
    ul {
      font-size: 1.1em;
      font-weight: 600;
    }
  }
  img.guarantee {
    display: none;
  }
}

#cta-wrap {
  background: #04325f;
}

#points-wrap {
  background: #f1f1f1;
  #points {
    h4 {
      margin-top: 0;
      &:before {
        display: inline-block;
        margin: 0 10px;
        width: 40px;
        height: 40px;
        content: '';
        background: transparent url(../images/steps-icon.png) no-repeat 0 0 / cover;
        vertical-align: middle;
        -webkit-transition: opacity 0.25s ease-out 0.25s;
        transition: opacity 0.25s ease-out 0.25s;
        -webkit-transition-delay: 0.1s;
        transition-delay: 0.1s;
        margin-bottom: 7px;
      }
      &.step-2:before {
        background-position: -40px 0;
        -webkit-transition-delay: 0.2s;
        transition-delay: 0.2s;
      }
      &.step-3:before {
        background-position: -80px 0;
        -webkit-transition-delay: 0.3s;
        transition-delay: 0.3s;
      }
    }
  }
}

.card {
  background-color: #f7fbfe;
  border-color: #c5e2f0;
}

/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  #points-wrap {
    #points {
      h4 {
        &:before {
          display: block;
          margin: 0 auto 10px auto;
          width: 60px;
          height: 60px;
        }
        &.step-2:before {
          background-position: -60px 0;
        }
        &.step-3:before {
          background-position: -120px 0;
        }
      }
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #header a.logo {
    img {
      max-width: 80px;
      margin-right: 15px;
    }
  }
}
// roughtly 900px/16px
@media only screen and (min-width: 56.25em) {
  #footer p.trustmark {
    display: block;
  }
  #header-wrap {
    border-top: 3px solid $theme-primary;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  #header a.logo {
    width: 70%;
    span.big {
      font-size: 1.9em;
      color: $theme-primary;
    }
  }
  #banner-wrap {
    #banner {
      ul {
        font-size: 1.2em;
      }
      img.seal {
        max-width: 90px;
      }
      img.guarantee {
        display: initial;
        position: absolute;
      }
    }
  }
  #points-wrap {
    #points {
      h4 {
        &:before {
          width: 120px;
          height: 120px;
        }
        &.step-2:before {
          background-position: -120px 0;
        }
        &.step-3:before {
          background-position: -240px 0;
        }
      }
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  #banner-wrap {
    #banner {
      background: url(../images/banner-thumbsup.png) no-repeat;
      background-position: 150px bottom;
    }
  }
}
